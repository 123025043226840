import { Label } from 'reactstrap'
import { formatDate } from 'Utils/Dates'
import { DatePickerSingle } from 'Shared/DatePicker'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import FormGroupField from 'Shared/Formik/FormGroupField'
import Tooltip from 'Shared/Tooltip'

const FormDatePickerSingle = ({ className, placeholder, label, labelClasses, name, onDatesChange, showErrors,
                                showTooltip, textToLeft, tooltipText }) => (
  <FormGroupField className={className} name={name} showErrors={showErrors}>
    {({ field, form: { errors, setFieldValue } }) => {
      const hasError = errors[name]

      const handleDateChange = date => {
        if (date) {
          const formattedDate = formatDate(date)
          setFieldValue(name, formattedDate)
          if (onDatesChange)
            onDatesChange({ [name]: formattedDate })
        }
      }

      return (
        <>
          {label && (
            <Label className={labelClasses} for={name}>
              {label}

              {showTooltip && (
                <>
                  <FontAwesomeIcon className="ms-1 text-muted" icon="circle-info" id={`date-${name}`} />

                  <Tooltip autoHide idTarget={`date-${name}`} value={tooltipText} />
                </>
              )}
            </Label>
          )}

          <DatePickerSingle block date={field.value} displayFormat="MM/DD/YYYY"
                            id={name} isOutsideRange={() => {}} label={placeholder}
                            onDateChange={handleDateChange} placeholder="MM/DD/YYYY" showDefaultInputIcon
                            textToLeft={textToLeft} updateDates={handleDateChange} />

          {(showErrors && hasError) && (
            <p className="alert alert-danger mt-2 mb-0">
              Please select a valid date to continue.
            </p>
          )}
        </>
      )
    }}
  </FormGroupField>
)

FormDatePickerSingle.defaultProps = {
  placeholder: null,
  isRequired: false,
  onDatesChange: () => {},
  showTooltip: false,
  showErrors: false,
  textToLeft: false,
}

export default FormDatePickerSingle
