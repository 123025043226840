import { useEffect, useState } from 'react'
import { Button, Label, Input, InputGroup, InputGroupText } from 'reactstrap'
import FormGroupField from 'Shared/Formik/FormGroupField'
import './Coupon.scss'

const errorMessage = 'Code must be at least 4 characters long and only consist of numbers (0-9) and capital letters (A-Z)'

const Coupon = ({ couponCode, disabled, name, showErrors }) => {
  const [clearCouponCode, setClearCouponCode] = useState(false)
  const [localValue, setLocalValue] = useState(couponCode)

  return (
    <FormGroupField className="coupon mb-0" name={name}>
      {({ form, meta: { error } }) => {
        const onClickApply = () => {
          // NOTE: we need to validate here, because the value is not saved on formik and schema doesn't validate
          if (/^[A-Z0-9]{4,50}$/.test(localValue)) {
            form.setFieldValue(name, localValue.trim())
            setClearCouponCode(true)
          } else
            form.setFieldError(name, errorMessage)
        }

        const onChange = ({ target }) => {
          const { value } = target
          if (!clearCouponCode)
            setLocalValue(value)
        }

        const onClear = () => {
          setLocalValue('')
          form.setFieldValue(name, '')
          setClearCouponCode(false)
        }

        useEffect(() => {
          if (couponCode && !localValue) {
            setLocalValue(couponCode)
            setClearCouponCode(true)
          }
        }, [couponCode])

        return (
          <>
            <Label for={name}>Coupon Code</Label>

            <InputGroup className="floating-label-height">
              <Input className="border-end-0" disabled={disabled} id={name} name={name} onChange={onChange}
                     placeholder="Enter Coupon Code" value={localValue} />

              {clearCouponCode && (
                <InputGroupText className="bg-white pe-2">
                  <Button aria-label="Clear" className="clear-button rounded-pill p-0 me-3" color="danger"
                          disabled={disabled} onClick={onClear}>
                    x
                  </Button>
                </InputGroupText>
              )}

              <Button color="blue-dark" disabled={disabled || clearCouponCode} onClick={onClickApply}>
                {clearCouponCode ? 'Applied' : 'Apply'}
              </Button>
            </InputGroup>

            {showErrors && error && (
              <div className="alert alert-danger formik-error-message mt-2 mb-0">
                {error}
              </div>
            )}
          </>
        )
      }}
    </FormGroupField>
  )
}

Coupon.defaultProps = {
  name: 'couponCode',
}

export default Coupon
