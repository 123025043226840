import { useEffect, useState } from 'react'
import cnames from 'classnames'
import { Button } from 'reactstrap'
import { AlertShowError } from 'Shared/Alert'
import { formSchema } from './formSchema'
import { getSessionStorage } from 'Utils/SessionStorage'
import FormDateRangePicker from 'Shared/Formik/FormDateRangePicker'
import FormDaysWeek from 'Shared/Formik/FormDaysWeek/FormDaysWeek'
import FormSites from './FormSites'
import FormWithValidations from 'Shared/Formik/FormWithValidations'
import './Form.scss'

const Form = ({ closureDates, error, loading, mutationData, saveData, sitesApi, toggle }) => {
  const [siteEditables, setSiteEditables] = useState([])
  const { campgroundSlug } = window.roverData || {}
  const { error: mutationError, loading: mutationLoading } = mutationData || {}
  const classes = cnames('form', { 'skeleton-loading': mutationLoading })
  const formSaved = getSessionStorage(`${campgroundSlug}-add-blackout-dates`)
  const initalFormValues = { days: [], from: '', sites: [], siteEditables: [], to: '' }

  const onSelectAllSites = willBeSelected => {
    setSiteEditables(prevSites => prevSites.map(prevSite => ({ ...prevSite, selected: willBeSelected })))
  }

  const onSelectSite = id => {
    setSiteEditables(prevSites => (
      prevSites.map(prevSite => {
        if (prevSite.id === id) return { ...prevSite, selected: !prevSite.selected }

        return prevSite
      })
    ))
  }

  const onSubmit = values => saveData(values)

  useEffect(() => {
    setSiteEditables(formSaved?.siteEditables || sitesApi)
  }, [])

  return (
    <FormWithValidations className={classes} formId="add-blackout-dates-form" initialValues={initalFormValues}
                         keySessionStorage="add-blackout-dates" onSubmit={onSubmit} saveSessionStorage
                         saveSessionStorageExtraData={{ siteEditables }} scrollToError
                         validationSchema={formSchema}>
      {({ values }) => {
        const { from, sites: sitesForm, to } = values
        const disabled = sitesForm.length === 0 || !from || !to

        return (
          <>
            <FormDateRangePicker checkInPlaceholder="From" checkOutPlaceholder="To" closureDates={closureDates}
                                 isRequired label="Dates" name="dates" numberOfMonths={1} showErrors />

            <FormDaysWeek className="mt-5" daysSelected={values.days} label="Nights of the week" name="days" />

            <hr className="m-0" />

            <FormSites errorSites={error} loading={loading} onSelectAllSites={onSelectAllSites}
                       onSelectSite={onSelectSite} sites={siteEditables} />

            <div className="border-top p-0 pt-3">
              {mutationError && <AlertShowError className="mb-3" error={mutationError} />}

              <div className="d-flex justify-content-between">
                <Button className="btn me-3 btn-outline-salmon" onClick={toggle}>Cancel</Button>

                <Button color="salmon" disabled={disabled} size="md" type="submit">
                  Apply
                </Button>
              </div>
            </div>
          </>
        )
      }}
    </FormWithValidations>
  )
}

export default Form
