import cnames from 'classnames'
import { ButtonGroup } from 'reactstrap'
import FormGroupField from 'Shared/Formik/FormGroupField'

const SwitchPills = ({ className, label, name, optionLeft, optionRight, valueLeft, valueRight }) => {
  const containerClass = cnames('switch-pills d-flex flex-centered', className)

  return (
    <FormGroupField name={name}>
      {({ field, form }) => {
        const rightActive = field.value === valueRight
        const leftClass = cnames('btn rounded-end rounded-pill', { 'btn-blue-dark active': !rightActive },
                                 { 'btn-outline-dark-blue': rightActive })
        const rightClass = cnames('btn rounded-start rounded-pill', { 'btn-blue-dark active': rightActive },
                                  { 'btn-outline-dark-blue': !rightActive })

        const onChange = value => form.setFieldValue(name, value)

        return (
          <>
            {label && <p className="mt-4 mb-1 text-gray-900">{label}</p>}

            <div className={containerClass}>
              <ButtonGroup className="w-100">
                <button className={leftClass} onClick={() => onChange(valueLeft)} type="button">
                  {optionLeft}
                </button>

                <button className={rightClass} onClick={() => onChange(valueRight)} type="button">
                  {optionRight}
                </button>
              </ButtonGroup>
            </div>
          </>
        )
      }}
    </FormGroupField>
  )
}

export default SwitchPills
