import PropTypes from 'prop-types'
import cnames from 'classnames'
import { Col, Row } from 'reactstrap'
import DiscountSelect from './DiscountSelect'
import PageSubtitle from 'Shared/PageSubtitle'
import Loading from 'Shared/Loading'
import FloatingLabelInput from 'Shared/FloatingLabelInput/FloatingLabelInput'
import FormTextInput from 'Shared/Formik/FormTextInput'

const Discount = ({ disabled, discountsArray, error, isFloating, isPos, labelClasses, layoutColumn,
                    loading, note, noteClasses, numberRequired, propsDiscountCodeInput, sizeInput, title,
                    titleClasses }) => {
  const discountSelectFormGroupClass = cnames({ 'mb-0': layoutColumn.first < 12 })
  const InputComponent = isFloating ? FloatingLabelInput : FormTextInput

  if (loading) return <Loading className="mt-5 text-center" />

  if (!discountsArray?.length || error) return null

  return (
    <Row className="discount mb-4">
      <Col md="12">
        <PageSubtitle className={titleClasses} text={title} />

        {note && <div className={noteClasses}>{note}</div>}
      </Col>

      <Col className="mb-2 mb-lg-0" md={layoutColumn.first}>
        <DiscountSelect {...{ disabled, isFloating, labelClasses, sizeInput }}
                        className={discountSelectFormGroupClass} discounts={discountsArray}
                        showRate={isPos} />
      </Col>

      {numberRequired && (
        <Col className="mb-0" md={layoutColumn.second}>
          <InputComponent {...{ labelClasses, sizeInput, ...propsDiscountCodeInput }} className="mb-0"
                          name="discount.code" showErrors={!isFloating} />
        </Col>
      )}
    </Row>
  )
}

Discount.defaultProps = {
  discountsArray: [],
  isFloating: false,
  labelClasses: '',
  layoutColumn: {
    first: 6,
    second: 6,
  },
  note: '',
  noteClasses: '',
  numberRequired: false,
  propsDiscountCodeInput: {
    label: 'Discount Code',
    placeholder: 'Discount Code',
  },
  sizeInput: '',
  title: 'Discounts',
  titleClasses: '',
}

Discount.propTypes = {
  discountsArray: PropTypes.arrayOf(PropTypes.shape({})),
  isFloating: PropTypes.bool,
  numberRequired: PropTypes.bool,
  labelClasses: PropTypes.string,
  layoutColumn: PropTypes.shape({
    first: PropTypes.number,
    second: PropTypes.number,
  }),
  note: PropTypes.string,
  noteClasses: PropTypes.string,
  propsDiscountCodeInput: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  sizeInput: PropTypes.string,
  title: PropTypes.string,
  titleClasses: PropTypes.string,
}

export default Discount
