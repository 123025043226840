import { useLayoutEffect } from 'react'

const useScrollToError = ({ formik, forceScroll }) => {
  const { errors, isSubmitting, isValidating } = formik

  useLayoutEffect(() => {
    const keys = Object.keys(errors)
    const errorElement = document.getElementsByClassName('has-error')[0]

    if (!!keys.length && errorElement && (forceScroll || (isSubmitting && !isValidating))) {
      errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      errorElement.focus({ preventScroll: true })
    }
  }, [isSubmitting, isValidating, errors])
}

export default useScrollToError
