import { useState } from 'react'
import { Input, Label } from 'reactstrap'
import cnames from 'classnames'
import FormGroupField from 'Shared/Formik/FormGroupField'
import RichText from 'Shared/RichText/RichText'
import { textareaMaxLength } from 'Shared/Formik/Utils'

const FormTextArea = ({ className, helpBottomText, helpText, id, inputClasses, isRequired, label, labelClasses,
                        maxLength, name, placeholder, showCharacters, showErrors, rows }) => {
  const [charactersCount, setCharactersCount] = useState(0)
  const classes = {
    input: cnames('control-textarea', inputClasses),
    label: cnames('main-label', labelClasses),
  }

  const handleCharactersCount = (e, field) => {
    setCharactersCount(e.target.value.length)
    field.onChange(e)
  }

  return (
    <FormGroupField className={className} name={name} showErrors={showErrors}>
      {({ field, hasErrorClass }) => {
        const initValue = field.value ?? ''
        const classesInput = cnames(classes.input, hasErrorClass)

        return (
          <>
            {label && (
              <Label className={classes.label} for={name}>
                {label}
                {isRequired && <span className="text-danger ms-1">*</span>}
              </Label>
            )}
            {helpText && <RichText className="help-text w-100" html={helpText} />}

            <Input {...field} className={classesInput} id={id || name} maxLength={maxLength} name={name}
                   placeholder={placeholder} rows={parseInt(rows)} type="textarea" value={initValue}
                   onChange={e => handleCharactersCount(e, field)} />

            <div className="text-muted d-flex justify-content-between">
              {helpBottomText && <small className="help-text w-100">{helpBottomText}</small>}

              {showCharacters && (
                <small className="text-muted text-end">
                  {charactersCount}/{maxLength}
                </small>
              )}
            </div>
          </>
        )
      }}
    </FormGroupField>
  )
}

FormTextArea.defaultProps = {
  isRequired: false,
  maxLength: textareaMaxLength,
  rows: 3,
  showCharacters: false,
  showErrors: false,
}

export default FormTextArea
