import { Label } from 'reactstrap'
import cnames from 'classnames'
import FontAwesomeIcon from 'Shared/FontAwesomeIcon/FontAwesomeIcon'
import FormGroupField from 'Shared/Formik/FormGroupField'
import CurrencyInput from 'Shared/CurrencyInput/CurrencyInput'
import RichText from 'Shared/RichText/RichText'
import Tooltip from 'Shared/Tooltip'

const FormCurrencyInput = ({ children, classContainerInput, classInputGroup, className, classTextGroup,
                             debouncedTime, handlerChange, helpText, helpTextAction, helpTextClass, id, icon,
                             inputClasses, inputSize, isRequired, label, labelClasses, name, max, placeholder,
                             showErrors, tooltip, tooltipId }) => {
  const classes = {
    groupInput: cnames('control-textarea', inputClasses),
    label: cnames('main-label', labelClasses),
    icon: cnames('text-muted'),
  }

  return (
    <FormGroupField className={className} name={name} showErrors={showErrors}>
      {({ field, form, hasErrorClass }) => {
        const onChange = value => {
          form.setFieldValue(name, value)
          handlerChange(name, value)
        }

        return (
          <>
            {children || (
              <div className="d-flex justify-content-between">
                {label && (
                  <Label className={classes.label} for={name}>
                      {label}

                      {isRequired && <span className="text-danger ms-1">*</span>}

                      {tooltip && (
                        <span className="ms-1" id={tooltipId}>
                          <FontAwesomeIcon className={classes.icon} icon={icon} />

                          <Tooltip idTarget={tooltipId} value={tooltip} />
                        </span>)}
                  </Label>
                )}

                {helpText && <RichText className={helpTextClass || 'help-text w-100'} html={helpText}
                                       handleOnClick={helpTextAction} />}
              </div>
            )}

            <div className={classContainerInput}>
              <CurrencyInput {...field} className={classes.groupInput} classInputGroup={classInputGroup}
                             classTextGroup={classTextGroup} debouncedTime={debouncedTime} id={id || name}
                             inputClass={hasErrorClass} inputSize={inputSize} max={max} onValueChange={onChange}
                             placeholder={placeholder} />
            </div>
          </>
        )
      }}
    </FormGroupField>
  )
}

FormCurrencyInput.defaultProps = {
  classTextGroup: null,
  debouncedTime: 600,
  handlerChange: () => {},
  helpTextAction: () => {},
  helpTextClass: '',
  icon: null,
  isRequired: false,
  inputSize: 'lg',
  tooltip: null,
  tooltipId: null,
}

export default FormCurrencyInput
