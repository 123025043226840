export const couponCodesState = {
  data: {
    coupon: null,
    coupons: [],
    couponCreated: null,
    couponsCount: 0,
    formAction: '',
  },
  error: null,
  loading: true,
  filters: {
    status: 'all',
    pagesCount: 0,
    totalCount: 0,
    currentPage: 1,
    perPage: 25,
  },
}
