import { Button } from 'reactstrap'
import cnames from 'classnames'

const DayButton = ({ colorButton, dayNumber, isSelected, onClick, value }) => {
  const className = cnames({ active: isSelected })

  return (
    <Button className={className} color={colorButton} onClick={() => onClick(dayNumber)}>
      {value}
    </Button>
  )
}

DayButton.defaultProps = {
  colorButton: 'secondary',
}

export default DayButton
