import PropTypes from 'prop-types'
import { Input, Label } from 'reactstrap'
import cnames from 'classnames'
import FormGroupField from 'Shared/Formik/FormGroupField'
import DiscountOptions from './DiscountOptions'

const DiscountSelect = ({ className, disabled, discounts, isFloating, labelClasses, showRate, sizeInput }) => (
  <FormGroupField className={className} name="discount.id">
    {({ field, form }) => {
      const classes = {
        input: cnames('form-select discountDropdown', { 'floating-label-height': isFloating }),
        label: cnames('main-label', labelClasses),
      }

      const dropdownChange = e => {
        const discountId = e.target.value
        if (!discountId) return clearDiscount()

        const discount = discounts.find(discount => discount.id === discountId)
        if (!discount) return clearDiscount()

        form.setFieldValue('discount', { ...discount, code: '' })
      }

      const clearDiscount = () => {
        // This property is needed to toggle the discount code field
        form.setFieldValue('discount', { id: '', isNumberRequired: false })
      }

      return (
        <>
          {!isFloating && <Label className={classes.label} for="discount.id">Discount</Label>}

          <Input {...field} bsSize={sizeInput} disabled={disabled} className={classes.input}
                 id="discounts-dropdown" name="discount.id" onBlur={dropdownChange} onChange={dropdownChange}
                 type="select">
            <option value="">-- Select Discount Club --</option>

            <DiscountOptions discounts={discounts} showRate={showRate} />
          </Input>
        </>
      )
    }}
  </FormGroupField>
)

DiscountSelect.defaultProps = {
  disabled: false,
  discounts: [],
  isFloating: false,
  labelClasses: '',
  sizeInput: '',
}

DiscountSelect.propTypes = {
  disabled: PropTypes.bool,
  discounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isFloating: PropTypes.bool,
  labelClasses: PropTypes.string,
  sizeInput: PropTypes.string,
}

export default DiscountSelect
