import { moment } from 'Utils/Dates'
import { ButtonGroup, Label } from 'reactstrap'
import { FieldArray } from 'formik'
import DayButton from './DayButton'
import FormErrorMessage from 'Shared/Formik/FormErrorMessage'

const FormDaysWeek = ({ className, colorButton, daysSelected, label, name }) => (
  <FieldArray name={name}>
    {({ push, remove }) => {
      const days = moment.weekdaysShort()

      const setDay = day => {
        const index = daysSelected.indexOf(day)
        if (index >= 0)
          remove(index)
        else
          push(day)
      }

      return (
        <div className={className}>
          <Label className="main-label" for={name}>{label}</Label>

          <ButtonGroup className="w-100">
            {days.map((day, index) => (
              <DayButton colorButton={colorButton} dayNumber={index} key={day}
                         isSelected={daysSelected.includes(index)} onClick={setDay} value={day} />
            ))}
          </ButtonGroup>

          <FormErrorMessage name={name} />

          <p className="fst-italic mt-2 mb-0 pb-2 text-gray-600 smaller">
            If no nights are selected, all nights of the week will be considered.
          </p>
        </div>
      )
    }}
  </FieldArray>
)

export default FormDaysWeek
